import { useState, useEffect } from 'react';
import BFF from '@/services/BFF';

const JQ_CAMPAIGN_SASHINGS = 'JQ_CAMPAIGN_SASHINGS';

const getCampaignPills = async () => {
  const pills = await BFF.getCampaignPills()();
  return pills;
};

const useCampaignPills = () => {
  const [campaignPills, setCampaignPills] = useState({});

  useEffect(() => {
    const sashingsJSON = sessionStorage.getItem(JQ_CAMPAIGN_SASHINGS);

    if (!sashingsJSON) {
      getCampaignPills().then((data) => {
        sessionStorage.setItem(JQ_CAMPAIGN_SASHINGS, JSON.stringify(data));
        setCampaignPills(data);
      });
      return;
    }
    setCampaignPills(JSON.parse(sashingsJSON));
  }, []);

  return { campaignPills };
};

export default useCampaignPills;
