import { concat, uniq } from 'ramda';
import sortPromotionCodes from '../sortPromotionCodes';

const getCampaignCodesFromPackageOffer = (packageOffer, campaignPills) => {
  if (!packageOffer || !packageOffer.promoCode) return [];

  const promotionCodes = uniq(
    concat(
      packageOffer.promoCode.outbound || [],
      packageOffer.promoCode.inbound || [],
    ),
  );
  const sortedCodes = sortPromotionCodes(promotionCodes, campaignPills);

  return sortedCodes || [];
};

export default getCampaignCodesFromPackageOffer;
