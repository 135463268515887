import { useMemo } from 'react';
import { ORIGINS } from '@/v2/../constants';
import { isAfter } from 'date-fns';

import isDomestic from '@/utils/isDomestic';
import formatDate from '@/libs/utils/formatDate';

const useTravelDates = (travelDateRange, routes, destinationCode) => {
  const filteredRoutes = useMemo(
    () =>
      travelDateRange &&
      routes
        ?.filter(({ origin, travelDates }) => {
          const isHotelDomestic = isDomestic(destinationCode);

          const isFlightDomestic = isDomestic(origin);

          const isValidTravelDates = travelDates && travelDates.length > 0;

          return (
            isValidTravelDates && (isHotelDomestic ? isFlightDomestic : true)
          );
        })
        .map(({ origin, travelDates }) => {
          return {
            origin:
              ORIGINS.find((o) => o.code === origin.toUpperCase())?.name ||
              origin,
            travelDates: travelDates
              .filter(
                ({ start }) =>
                  !isAfter(
                    new Date(start.utc),
                    new Date(travelDateRange.end.utc),
                  ),
              )
              .map(({ start, end }) => {
                return `${formatDate(start.utc)} - ${formatDate(end.utc)}`;
              }),
          };
        }),
    [destinationCode, routes, travelDateRange],
  );

  return filteredRoutes;
};

export default useTravelDates;
