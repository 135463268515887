import React from 'react';
import { Box, Flex, Icon } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';
import Heading from '@/components/Heading';
import Text from '@/components/Text';
import Modal from '@/components/Modal';
import InclusionPropTypes from '@/propTypes/Inclusion';
import ConditionsMarkdown from '@/components/ConditionsMarkdown';
import OfferInclusions from '@/components/OfferInclusions';
import pluralize from 'pluralize';
import Divider from '@/components/Divider';
import withProps from '@/components/withProps/withProps';
import useTravelDates from './hooks/useTravelDates';
import { ANCIL_TERMS } from './constants/ancilTerms';
import formatDate from '@/libs/utils/formatDate';

const BodyText = withProps({
  fontSize: ['xs', null, 'sm'],
})(Text);
const ViewDetailsModal = ({
  close,
  inclusions,
  terms,
  title,
  minNights,
  travelDateRange,
  routes,
  destinationCode,
}) => {
  const filteredRoutes = useTravelDates(
    travelDateRange,
    routes,
    destinationCode,
  );

  const formattedTravelDateRange =
    travelDateRange &&
    `${formatDate(travelDateRange.start.utc)} - ${formatDate(travelDateRange.end.utc)}`;

  return (
    <Modal size="lg" onClose={() => close()}>
      <Modal.Header>Package details and terms</Modal.Header>
      <Modal.Body>
        <Heading.h4 fontSize={['sm', null, 'md']}>{title}</Heading.h4>
        <Box>
          <Box mb={2} mt={4}>
            <Text bold>Minimum stay</Text>
          </Box>
          <Box>
            <BodyText>{pluralize('night', minNights, true)}</BodyText>
            <Divider />
          </Box>
        </Box>
        <Box color="text.body" mt="2">
          {inclusions && (
            <Box my={5}>
              <OfferInclusions
                inclusions={inclusions}
                shownInclusions={inclusions.length}
                showDivider
              />
            </Box>
          )}
          <Box>
            <Box mb={2}>
              <Text bold>Valid travel dates</Text>
            </Box>
            {filteredRoutes && filteredRoutes.length > 0 ? (
              filteredRoutes.map(({ origin, travelDates }) => (
                <Box key={origin}>
                  <Box mb={1}>
                    <BodyText>From {origin}</BodyText>
                  </Box>
                  {travelDates.map((date) => (
                    <Box key={date} ml={1}>
                      <li>
                        <BodyText>{date}</BodyText>
                      </li>
                    </Box>
                  ))}
                  <Divider />
                </Box>
              ))
            ) : (
              <Box ml={1}>
                <li>
                  <BodyText>{formattedTravelDateRange}</BodyText>
                </li>
                <Divider />
              </Box>
            )}
          </Box>
          <Box>
            <Box mb={2} mt={4}>
              <Text bold>{ANCIL_TERMS.title}</Text>
            </Box>
            <Flex>
              <Icon
                color="icon.inclusion"
                size={[22, null, 24]}
                name={ANCIL_TERMS.inclusion.code}
              />
              <Flex gap="1" flexDirection="column">
                <Text ml="2" medium>
                  {ANCIL_TERMS.inclusion.name}
                </Text>
                <Text fontSize={['xxs', null, 'xs']} ml="2">
                  {ANCIL_TERMS.inclusion.subtitle}
                </Text>
              </Flex>
            </Flex>
            <Divider />
          </Box>
          <Box mt={5}>
            <Text>
              <ConditionsMarkdown markdown={terms} paragraphFontSize="sm" />
            </Text>
          </Box>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

ViewDetailsModal.defaultProps = {
  title: null,
  inclusions: [],
};

ViewDetailsModal.propTypes = {
  close: PropTypes.func.isRequired,
  terms: PropTypes.string.isRequired,
  inclusions: PropTypes.arrayOf(PropTypes.shape(InclusionPropTypes)),
  title: PropTypes.string,
};

export default ViewDetailsModal;
