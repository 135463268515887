// Travel Types
export const DOMESTIC = 'DOMESTIC';
export const INTERNATIONAL = 'INTERNATIONAL';
export const TRANS_TASMAN = 'TRANS_TASMAN';

// Date/Time
export const TIMEZONE = 'Australia/Melbourne';

// App
export const BASE_PATH = process.env.BASE_PATH || '';
export const NEXT__PUBLIC_BASE_URL = process.env.NEXT__PUBLIC_BASE_URL || '';

// Last Minute campaign
export const LAST_MINUTE_CAMPAIGN_CODE = 'LM';
