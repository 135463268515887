const getIconNameFromCode = (code = '') =>
  ({
    adults_only: 'people',
    all_inclusive: 'allInclusive',
    apartment_style: 'locationCity',
    beach_access: 'beach',
    beachfront: 'beachAccess',
    bottle_of_wine: 'localBar',
    boutique: 'roomService',
    breakfast: 'freeBreakfast',
    bucketlist: 'checkBox',
    camera_alt: 'activities',
    card_giftcard: 'welcomeGift',
    check: 'inclusionDefault',
    child_friendly: 'kids',
    city_break: 'locationCity',
    club_access: 'localPlay',
    destination: 'place',
    directions_bus: 'transfer',
    directions_car: 'parking',
    experience: 'naturePeople',
    face: 'kidsEatFree',
    family_friendly: 'childCare',
    flag: 'tour',
    flights: 'flights',
    food_and_wine: 'localDining',
    golf_course: 'golf',
    hot_tub: 'hotTub',
    hotel: 'stay',
    indulgence: 'spa',
    internet: 'wifi',
    kids_eat_free: 'restaurant',
    kids_stay_free: 'accessibility',
    late_checkout: 'accessTime',
    liquor: 'miniBar',
    local_activity: 'clubAccess',
    local_cafe: 'breakfast',
    local_offer: 'voucher',
    long_stay: 'eventNote',
    luxury: 'star',
    midweek_break: 'dateRange',
    outline_free_cancellation: 'refundable',
    parking: 'localParking',
    place: 'place',
    points: 'qffPoints',
    pointsPay: 'pointsPay',
    regional_escape: 'terrain',
    restaurant: 'foodRestaurant',
    romantic_getaway: 'favorite',
    sailing: 'sailing',
    sale: 'sale',
    schedule: 'lateCheckout',
    shopping: 'localMall',
    short_break: 'eventAvailable',
    spa_voucher: 'spa',
    spa: 'spa',
    special_event: 'localActivity',
    stars: 'star',
    statusCredits: 'stars',
    transfers: 'directionsBus',
    upgrade: 'arrowUpward',
    weekend_escapes: 'event',
    wifi: 'wifi',
    wine_bar: 'wine',
    baggage: 'baggageBG07',
  })[code] || 'inclusionDefault';

export default getIconNameFromCode;
