import PropTypes from 'prop-types';
import { unless, take } from 'ramda';
import React, { useState } from 'react';
import { Box, Flex, Icon } from '@qga/roo-ui/components';
import Text from '@/components/Text';
import Inclusion from '@/components/Inclusion';
import InclusionPropTypes from '@/propTypes/Inclusion';
import LinkButton from '@/components/LinkButton';
import Divider from '@/components/Divider';

const OfferInclusions = ({
  inclusions = [],
  twoColumns,
  title,
  showMoreText,
  showLessText,
  shownInclusions,
  showDivider,
  hideSubtitle,
}) => {
  const [expanded, setExpanded] = useState(false);
  const onClick = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <>
      <Box mb={twoColumns ? 4 : 2}>
        <Text bold>{title}</Text>
      </Box>
      {twoColumns ? (
        <Flex>
          <Flex flexDirection="column" width="50%">
            {inclusions.slice(0, 4).map((inclusion, index) => (
              <Box key={index} mb="4" width="100%">
                <Inclusion inclusion={inclusion} hideSubtitle={hideSubtitle} />
              </Box>
            ))}
          </Flex>
          <Flex flexDirection="column" width="50%">
            {inclusions.slice(4, 9).map((inclusion, index) => (
              <Box key={index} mb="4" width="100%">
                <Inclusion inclusion={inclusion} hideSubtitle={hideSubtitle} />
              </Box>
            ))}
          </Flex>
        </Flex>
      ) : (
        <Box>
          {unless(
            () => expanded,
            take(shownInclusions),
          )(inclusions)?.map((inclusion, index) => (
            <Box key={index}>
              <Box mr="5" mb="1">
                <Inclusion inclusion={inclusion} hideSubtitle={hideSubtitle} />
              </Box>
              {showDivider && <Divider />}
            </Box>
          ))}
          {inclusions.length > shownInclusions && (
            <LinkButton noPadding onClick={onClick}>
              <Text bold link>
                {expanded ? showLessText : showMoreText}
              </Text>
              <Icon name={expanded ? 'expandLess' : 'expandMore'} />
            </LinkButton>
          )}
        </Box>
      )}
    </>
  );
};

OfferInclusions.defaultProps = {
  twoColumns: false,
  showDivider: false,
  hideSubtitle: false,
  title: 'Package inclusions:',
  showMoreText: 'Show more',
  showLessText: 'Show less',
  shownInclusions: 4,
};

OfferInclusions.propTypes = {
  inclusions: PropTypes.arrayOf(PropTypes.shape(InclusionPropTypes)).isRequired,
  twoColumns: PropTypes.bool,
  hideSubtitle: PropTypes.bool,
  showDivider: PropTypes.bool,
  title: PropTypes.string,
  showMoreText: PropTypes.string,
  showLessText: PropTypes.string,
  shownInclusions: PropTypes.number,
};

export default OfferInclusions;
