import React from 'react';
import { Box } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';
import Heading from '@/components/Heading';
import Text from '@/components/Text';
import Modal from '@/components/Modal';
import ConditionsMarkdown from '@/components/ConditionsMarkdown';

const TermsAndConditionModal = ({ close, content, subtitle, title }) => (
  <Modal size="lg" onClose={() => close()}>
    <Modal.Header>{title}</Modal.Header>
    <Modal.Body>
      {subtitle && <Heading.h4 fontSize="md">{subtitle}</Heading.h4>}
      <Box color="text.body" mt="2">
        <Text>
          <ConditionsMarkdown markdown={content} paragraphFontSize="sm" />
        </Text>
      </Box>
    </Modal.Body>
  </Modal>
);

TermsAndConditionModal.defaultProps = {
  title: 'Offer terms & conditions',
  subtitle: undefined,
};

TermsAndConditionModal.propTypes = {
  close: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default TermsAndConditionModal;
