import { prepend, when } from 'ramda';
import { logger } from '@/libs/utils';

const prependFlightCampaignInclusion = (inclusions, flightBadgeText, terms) => {
  if (!terms) {
    if (flightBadgeText) {
      logger.error('No terms to include for flight campaign');
    }
    return inclusions;
  }

  return when(
    () => flightBadgeText,
    prepend({
      code: 'sale',
      name: flightBadgeText + ' ' + 'flights',
      terms: { text: terms },
    }),
  )(inclusions);
};

export default prependFlightCampaignInclusion;
