import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { Icon, Flex } from '@qga/roo-ui/components';
import Text from '@/components/Text';
import InclusionPropTypes from '@/propTypes/Inclusion';
import getIconNameFromCode from './utils/getIconNameFromCode';
import ToggleContent from '@/libs/v2/components/ToggleContent/ToggleContent';
import LinkButton from '@/components/LinkButton';
import TermsAndConditionModal from '@/components/TermsAndConditionModal';

const StyledIcon = styled(Icon)`
  float: left;
`;

const Inclusion = ({ inclusion, hideSubtitle, isLastShownInclusion }) => {
  const { code, subtitle, icon, title, terms } = inclusion;
  const name = inclusion.name || title;
  const iconName = icon ?? getIconNameFromCode(code);

  const inclusionContent = (
    <>
      <StyledIcon
        color="icon.inclusion"
        size={[22, null, 24]}
        name={iconName || 'done'}
      />
      <Flex flexDirection="column">
        <Text ml="2" medium>
          {name}
          {isLastShownInclusion && ' and more...'}
          {!!terms && (
            <Icon size={14} name="info" mb={1} ml={1} color="icon.inclusion" />
          )}
        </Text>
        {!hideSubtitle && subtitle && (
          <Text hint fontSize="xs" ml="2">
            {subtitle}
          </Text>
        )}
      </Flex>
    </>
  );
  return (
    <>
      {!!terms ? (
        <ToggleContent
          toggle={(show) => (
            <LinkButton
              hover
              noPadding
              fontSize="sm"
              onClick={(e) => {
                e.preventDefault();
                show();
              }}
            >
              {inclusionContent}
            </LinkButton>
          )}
          content={(closeModal) => (
            <TermsAndConditionModal
              close={closeModal}
              title={name}
              content={terms.text}
            />
          )}
        />
      ) : (
        inclusionContent
      )}
    </>
  );
};

Inclusion.defaultProps = {
  hideSubtitle: false,
  isLastShownInclusion: false,
};

Inclusion.propTypes = {
  inclusion: PropTypes.shape(InclusionPropTypes).isRequired,
  hideSubtitle: PropTypes.bool,
  isLastShownInclusion: PropTypes.bool,
};

export default Inclusion;
