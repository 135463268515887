import { zipObj, range, anyPass, isNil, isEmpty } from 'ramda';

const sortPromotionCodes = (codes, campaignPills) => {
  if (anyPass([isNil, isEmpty])(campaignPills)) {
    return codes && codes.sort().reverse();
  }

  const campaignIndex = zipObj(
    Object.keys(campaignPills),
    range(1, Object.keys(campaignPills).length + 1),
  );

  return (
    (codes &&
      codes.sort((a, b) => {
        if (anyPass([isNil, isEmpty])(campaignIndex[a])) {
          return 1;
        }
        if (anyPass([isNil, isEmpty])(campaignIndex[b])) {
          return -1;
        }
        return campaignIndex[a] - campaignIndex[b];
      })) ||
    []
  );
};

export default sortPromotionCodes;
