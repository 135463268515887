import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ListItem } from '@qga/roo-ui/components';
import Heading from '@/components/Heading';
import Text from '@/components/Text';
import Link from '../Link';
import withProps from '../withProps';

const ConditionsLink = (external) =>
  withProps({
    external,
  })(styled(Link.Text)``);

const ConditionsTitle = () =>
  withProps({
    pb: '3',
  })(Heading.h4);

const ConditionsHeading = () =>
  withProps({
    mt: '8',
    mb: '3',
  })(Heading.h5);

const ConditionsSubHeading = () =>
  withProps({
    mt: '6',
    mb: '3',
  })(Heading.h6);

const ConditionsParagraph = (fontSize, mb) =>
  withProps({
    display: 'block',
    mt: '0',
    mb,
    fontSize,
  })(Text);

const ConditionsTableCell = () =>
  withProps({
    textAlign: 'left',
  })(styled('td')`
    padding: 0;
    padding-right: 8px;
    padding-bottom: 12px;
    vertical-align: top;
  `);

// React markdown does not allow tables with header cells but we don't want to show them (so far)
const ConditionsTableHeading = () => styled('th')`
  display: none;
`;

// eslint-disable-next-line react/prop-types
const ConditionsListItem =
  () =>
  ({ children }) => <ListItem>{children && <Text>{children}</Text>}</ListItem>;

const overrideRenderers = (
  paragraphFontSize,
  paragraphMarginBottom,
  external,
) => ({
  span: ConditionsParagraph(paragraphFontSize, paragraphMarginBottom),
  paragraph: ConditionsParagraph(paragraphFontSize, paragraphMarginBottom),
  listItem: ConditionsListItem(),
  // eslint-disable-next-line react/prop-types
  tableCell: ({ isHeader, children }) => {
    const Component = isHeader
      ? ConditionsTableHeading()
      : ConditionsTableCell();
    return <Component>{children}</Component>;
  },
  link: ConditionsLink(external),
  // eslint-disable-next-line react/prop-types
  heading: ({ level, children }) => {
    const Component =
      {
        1: ConditionsTitle(),
        2: ConditionsHeading(),
      }[level] || ConditionsSubHeading();
    return <Component>{children}</Component>;
  },
});

const ConditionsMarkdown = ({
  markdown,
  paragraphFontSize,
  paragraphMarginBottom,
  external,
}) => (
  <ReactMarkdown
    source={markdown}
    renderers={overrideRenderers(
      paragraphFontSize,
      paragraphMarginBottom,
      external,
    )}
  />
);

ConditionsMarkdown.propTypes = {
  markdown: PropTypes.string,
  paragraphFontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  paragraphMarginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  external: PropTypes.bool,
};

ConditionsMarkdown.defaultProps = {
  markdown: '',
  paragraphFontSize: 'sm',
  paragraphMarginBottom: 6,
  external: true,
};

export default ConditionsMarkdown;
