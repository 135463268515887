const KFF_PROMOTION_CODE = 'KFF';

export const isFlightPriceForAdult = (flight) => {
  if (!flight) return false;

  return flight.promotionCode === KFF_PROMOTION_CODE;
};

export const isFlightsPriceForAdult = (flights) => {
  if (!flights) return false;

  return flights.some((flight) => isFlightPriceForAdult(flight));
};

export const isPackageOfferPriceForAdult = (packageOffer) => {
  if (!packageOffer || !packageOffer.promoCode) {
    return {
      priceForAdult: false,
      hidePriceSubText: false,
    };
  }

  const isOutboundPromoted =
    packageOffer.promoCode.outbound &&
    packageOffer.promoCode.outbound.includes(KFF_PROMOTION_CODE);
  const isInboundPromoted =
    packageOffer.promoCode.inbound &&
    packageOffer.promoCode.inbound.includes(KFF_PROMOTION_CODE);
  const priceForAdult = isOutboundPromoted && isInboundPromoted;

  return {
    priceForAdult,
    hidePriceSubText:
      !priceForAdult && (isOutboundPromoted || isInboundPromoted),
  };
};

const isFlightJourneyPriceForAdult = (flightJourney) => {
  if (!flightJourney) {
    return {
      priceForAdult: false,
      hidePriceSubText: false,
    };
  }

  const isOutboundPromoted = isFlightsPriceForAdult(flightJourney.outbound);
  const isInboundPromoted = isFlightsPriceForAdult(flightJourney.inbound);
  const priceForAdult = isOutboundPromoted && isInboundPromoted;

  return {
    priceForAdult,
    hidePriceSubText:
      !priceForAdult && (isOutboundPromoted || isInboundPromoted),
  };
};
export default isFlightJourneyPriceForAdult;
