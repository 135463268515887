import { ALL_DESTINATIONS } from '@/constants';

const filterCampaignsByDestination = (campaigns, destination) => {
  if (!Array.isArray(campaigns)) {
    return [];
  }

  return campaigns.filter((c) => {
    const destinations = (c && c.activeDestinations) || [];
    return (
      destinations.includes(destination) ||
      destinations.includes(ALL_DESTINATIONS)
    );
  });
};

export default filterCampaignsByDestination;
