import PropTypes from 'prop-types';
import Flights from '@/propTypes/Flights';
import { LAST_MINUTE_CAMPAIGN_CODE } from '@/v2/constants';

const getCampaignFlightInclusionCode = (hasSearchQuery, flightSegments) => {
  let dominantCode = '';
  const inboundCode = flightSegments?.inbound[0]?.promotionCode;
  const outboundCode = flightSegments?.outbound[0]?.promotionCode;

  // no flight campaign inclusions
  if (!inboundCode && !outboundCode) {
    return dominantCode;
  }

  // if flight campaign exists
  if (hasSearchQuery && (!!inboundCode || !!outboundCode)) {
    // Get the dominant campaign code (LM: Last minute is the weaker code)
    if (inboundCode === LAST_MINUTE_CAMPAIGN_CODE && !!outboundCode) {
      dominantCode = outboundCode;
    } else if (!!inboundCode && outboundCode === LAST_MINUTE_CAMPAIGN_CODE) {
      dominantCode = inboundCode;
    }
    // if inbound and outbound flights both 'Last minute', then the dominant code is 'Last minute'
    else if (
      inboundCode === LAST_MINUTE_CAMPAIGN_CODE &&
      outboundCode === LAST_MINUTE_CAMPAIGN_CODE
    ) {
      dominantCode = inboundCode;
    }
    // standard Campaigns
    else {
      dominantCode = !!inboundCode ? inboundCode : outboundCode;
    }
  }

  return dominantCode;
};

getCampaignFlightInclusionCode.defaultProps = {
  hasSearchQuery: undefined,
  flightSegments: undefined,
};

getCampaignFlightInclusionCode.propTypes = {
  hasSearchQuery: PropTypes.bool,
  flightSegments: PropTypes.shape(Flights),
};

export default getCampaignFlightInclusionCode;
